import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import NavBar from "../components/navbar"
import "../components/login.css"
import { isLoggedIn, getUser, refreshToken } from "../services/auth"

// import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row, Card, Button, CardDeck } from "react-bootstrap"

const dictionary = {
  water: "水源",
  rice: "大米",
  cow: "奶牛",
  rock: "燧石",
  nfood: "接骨木莓",
  rfood: "蓝莓",
  srfood: "覆盆子",
  ssrfood: "云莓",
  wood: "木材",
  milk: "牛奶",
  animal: "鱼",
  net: "渔网",
  fork: "鱼叉",
}

const image_dictionary = {
  water: "../images/water.png",
  rice: "../images/rice.png",
  cow: "../images/cow.png",
  rock: "../images/rock.png",
  nfood: "../images/jiegu.png",
  rfood: "../images/lanmei.png",
  srfood: "../images/fupengzi.png",
  ssrfood: "../images/yunmei.png",
  wood: "../images/wood.png",
  animal: "../images/fish.png",
  milk: "../images/milk.png",
  tarp: "../images/tarp.png",
  grass: "../images/grass.png",
  fork: "../images/fork.png",
}
const BASE_URL = "47.99.220.122:8000"
export default function History() {
  const [record, setRecord] = useState([])
  useEffect(() => {
    refreshToken()
    if (!isLoggedIn()) return
    const accessToken = getUser().access
    ;(async () => {
      const pollsurl = BASE_URL + "/login/getRecord"
      const method = "get"
      const headers = { Authorization: `Bearer ${accessToken}` }
      const response = await fetch(pollsurl, { method, headers })
      if (response.ok) {
        const record_json = await response.json()
        setRecord(record_json[0])
      } else {
        console.error("could not get the record ")
      }
    })()
  }, [isLoggedIn])
  const cardList = data => {
    console.log(typeof data)
    console.log(data)
    var new_data = Object.entries(data).filter((obj, index) => {
      console.log(obj, index)
      console.log("current value is " + obj.values)
      if (obj[1] > 0) {
        return obj
      }
    })
    console.log("data now is " + new_data)
    console.log("data type now is " + typeof new_data)
    const keys = Object.keys(new_data)
    const values = Object.values(new_data)

    return keys.map((key, index) => (
      <Card
        style={{
          width: "12rem",
          backgroundColor: "transparent",
          border: "0",
        }}
      >
        <Card.Img variant="top" src={image_dictionary[values[index][0]]} />
        <Card.Body style={{ "background-color": "white" }}>
          <Card.Title>{dictionary[values[index][0]]}</Card.Title>
          <Card.Text>当前剩余量：{values[index][1]}</Card.Text>
          <Button variant="primary" href="/collect">
            采集
          </Button>
        </Card.Body>
      </Card>
    ))
  }

  return (
    <div>
      <NavBar />
      <div className="historycenter">
        <h1> 部落{record.tribe_name}物资总量 </h1>
        <CardDeck>{cardList(record)}</CardDeck>
      </div>
    </div>
  )
}
